<template>
    <div class="edu-frame">
        <edu-nav-bar title="错误" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="content-title">温馨提示</div>
            <hr class="split-line"/>
            <div class="content-detail" v-html="msg">
            </div>
        </div>
    </div>
</template>
<script>
import EduNavBar from "@/components/EduNavBar";
export default {
    components: {
        EduNavBar
    },
    data() {
      return {
          show: false,
          msg: ''
      }
    },
    mounted() {
        this.msg = this.$route.params.msg
    }
}
</script>
<style scoped>
    .content-frame {
        width: calc(95% - 30px);
        margin: 8px auto;
        padding: 0 15px;
        height: calc(100% - 46px - 16px);
        background-color: white;
        color: black;
        position: relative;
        overflow: auto;
    }
    .split-line {
        width: 95%;
        margin: 0;
        padding: 0;
        height: 1px;
        background-color: #dddddd;
        border: 0;
    }
    .content-title {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;

    }
    .content-detail {
        font-size: 14px;
        margin: 15px 0;
    }

</style>
